import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { objToString } from '../utils';
import { getEnv } from '../utils/getEnv';
import { getLocalStorage } from '../utils/getLocalStorage';
import { AbstractStorage } from './abstract-storage';

const storageKey = getEnv('storageKey', 'mySuite');
const EOF = ';';

@Injectable({ providedIn: 'root' })
export class ApplicationStorageService extends AbstractStorage implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private storage?: Storage;

  constructor() {
    super();

    // Load and decode personal settings
    this.values = collectValuesFromStorage(this.getStorage());

    // Deprecation fix
    this._replaceProp('customer.onlyActive', 'customer.onlyActiveMeters');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Used to deprecate a value.
   *
   * @param prop - the property holding the deprecated value
   * @param withProp - the property which should contain the value
   */
  private _replaceProp(prop: string, withProp: string) {
    if (this.hasItem(prop)) {
      this.setItem(withProp, this.getItem(prop));
      this.removeItem(prop);
    }
  }

  getStorage() {
    if (!this.storage) {
      this.storage = getLocalStorage();
    }
    return this.storage;
  }

  /**
   * Encode and store values
   */
  protected storeCurrent() {
    try {
      // Remove previous buffer from storage to reduce overflowing
      const storage = this.getStorage();
      const keys: string[] = [];
      for (let j = 0; j < storage.length; j++) {
        storage.key(j)?.includes(storageKey) && keys.push(String(storage.key(j)));
      }
      keys.forEach(k => storage.removeItem(k));

      const valueStr = window.btoa(encodeURIComponent(objToString(this.values))) + EOF;
      // Split into chunks so that we avoid hitting browser limits
      const buffer = valueStr.match(/.{1,100000}/g);
      buffer?.forEach((v, i) => {
        const suffix = [...new Array(i)].map(() => '_').join('');
        storage.setItem(storageKey + suffix, v);
      });
    } catch (ex) {
      console.error(this.values, ex);
    }
  }

  reset() {
    const storage = this.getStorage();
    const keys: string[] = [];
    for (let j = 0; j < storage.length; j++) {
      storage.key(j)?.includes(storageKey) && keys.push(String(storage.key(j)));
    }
    keys.forEach(key => storage.removeItem(key));
  }
}

export function collectValuesFromStorage(storage = getLocalStorage()): any {
  // Load and decode personal settings
  const keys: string[] = [];
  const value: string[] = [];
  for (let j = 0; j < storage.length; j++) {
    storage.key(j)?.includes(storageKey) && keys.push(String(storage.key(j)));
  }
  keys.sort((a, b) => (a.length < b.length ? -1 : 1)).forEach(key => value.push(storage.getItem(key) || ''));

  const valueStr = value.length > 0 ? value.join('') : '{}';
  let values = {};
  try {
    // Sometimes when storage is shared between a lot of environments
    // (such as it is when debugging localhost), the chunked storage can
    // have residue from larger configurations. This will make sure
    // that we skip all chunks which are part of this overflow.
    const str = valueStr.substring(0, valueStr.indexOf(EOF) > 0 ? valueStr.indexOf(EOF) : valueStr.length);
    // Decrypt the valuestring (NOTE! The previous step sometimes fails,
    // so we have to fallback to the original valuestring)
    const decoded = window.atob(str || valueStr);
    // Decode the valuestring
    const unescaped = decodeURIComponent(decoded);
    // Parse the valuestring
    values = JSON.parse(unescaped);
  } catch (ex) {
    const err = 'DEBUG: Cannot parse previous settings. Starting blank...';
  }
  return values;
}

/**
 * Use only when injector is not available
 */
export function getAppSetting(identifier: string, defaultVal = '') {
  const values = collectValuesFromStorage();

  // Retreive identifier from setting
  if (identifier.indexOf('.') > -1) {
    return identifier
      .split('.')
      .reduce((acc, key, idx, arr) => acc[key] || (idx < arr.length - 1 ? {} : defaultVal), values);
  }
  return values[identifier] || {};
}
